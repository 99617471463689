<script>
import { useFetch, useTitle } from "@vueuse/core";
import SearchItem from "./SearchItem.vue";
import { useStore } from "vuex";
import { computed, onMounted, provide, ref, watch } from "vue";
import { useRoute } from "vue-router";
// import SearchPagination from "./SearchPagination.vue";
export default {
  components: {
    SearchItem,
    // SearchPagination
  },
  name: "SearchList",
  setup() {
    const store = useStore();
    const route = useRoute();
    const searchResult = computed(() => store.getters.getSearchResult);
    const resultLength = computed(() => store.getters.getSearchResultLen);
    const searchInput = computed(() => store.getters.getSearchStr);
    const isFinish = ref(false);
    const currentPage = ref(1);
    const perPage = ref(5);
    const offsetPage = ref(0);
    const filtered = computed(() => searchResult.value);

    // const nextPage = () => {
    //   offsetPage.value += 5;
    // };

    // const backPage = () => {
    //   if (offsetPage.value > 0) offsetPage.value -= 5;
    // };

    const goToPage = (n) => {
      offsetPage.value = perPage.value * n;
    };

    const nP = () => {
      filtered.value.slice(0, perPage.value);
    };

    const bP = () => {
      filtered.value.slice(0, perPage.value);
    };

    provide("current_page", currentPage);
    provide("per_page", perPage);
    provide("offset", offsetPage);
    provide("nextPage", nP);
    provide("backPage", bP);
    provide("goToPage", goToPage);

    function fetchSearch(slug) {
      isFinish.value = false;
      if (slug === "") {
        isFinish.value = false;
      } else {
        const { data, isFinished } = useFetch(
          `https://admin.dss-sport.ru/wp-json/wp/v2/search?search="${slug}"&per_page=50`,
          { refetch: true }
        )
          .get()
          .json();
        store.commit("SET_SEARCH_RESULT", {
          searchResult: data,
        });
        isFinish.value = isFinished;
      }
    }

    watch(
      () => route.query.search,
      async () => {
        try {
          if (route.query.search != undefined) fetchSearch(route.query?.search);
          useTitle(
            "Результаты поиска: " +
              searchInput.value +
              " - Дирекция спортивных сооружений"
          );
        } catch (e) {
          console.error(e);
        }
      }
    );

    onMounted(() => {
      fetchSearch(route.query?.search);
      useTitle(
        "Результаты поиска: " +
          searchInput.value +
          " - Дирекция спортивных сооружений"
      );
    });

    return {
      searchResult,
      resultLength,
      filtered,
      searchInputF: computed(() => `"${searchInput.value}"`),
    };
  },
};
</script>
<template>
  <div class="search__list">
    <h3 class="search__list__count" v-show="resultLength >= 1">
      Результаты поиска {{ searchInputF }}
    </h3>
    <template class="results" v-if="resultLength >= 1">
      <search-item
        v-for="(item, n) in searchResult"
        :key="item.id"
        :item="item"
        :position="n"
      />
    </template>
    <h3 v-else-if="resultLength === 0">
      Результатов по вашему запросу не найдено
    </h3>
  </div>
  <!-- <search-pagination v-show="resultLength >= 1" /> -->
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.search__list
  position: relative
  &__count
    margin-bottom: $default-padding*2
</style>
