<script>
import SearchList from "../components/SearchList.vue";
export default {
  components: { SearchList },
};
</script>
<template>
  <div class="search search-view">
    <div class="search__wrapper">
      <h1 class="search__wrapper--title">Результаты поиска</h1>
      <div class="search__wrapper--content">
        <search-list />
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.search
  max-width: $default-padding*51.5
  margin: 0 auto 0 $default-padding*7.5
  @media (max-width: $sizes-exl + $default-padding*16.25)
    margin: auto
  &__wrapper
    position: relative
    padding: $default-padding*2.8 0
    @media only screen and (max-width: $sizes-md)
      padding: $default-padding*.8 $default-padding*1.25
    &--title
      font-size: $base-font-size*3
      font-weight: 800
      line-height: $base-font-size*3.4
      letter-spacing: 0.05em
      text-transform: uppercase
      max-width: $default-padding*40.6
      margin-bottom: $default-padding*2
    &--content
      position: relative
      margin-top: $default-padding*2
      & h2
        font-size: $base-font-size*2.2
        font-weight: 500
      & p
        line-height: $base-font-size*1.65
</style>
