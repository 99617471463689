<script>
import { useFetch } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    position: {
      type: Number,
      default: 0,
    },
  },
  name: "SearchItem",
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const pageData = computed(() => store.getters.getItemData);
    const pageDataType = ref("");
    let isLoading = ref(true);

    function setNews(id) {
      isLoading.value = true;
      const { data, isFetching } = useFetch(
        `https://admin.dss-sport.ru/wp-json/wp/v2/posts/${id}`,
        { refetch: true }
      )
        .get()
        .json();
      store.commit("SET_NEWS_ITEM_DATA", {
        newsData: data,
      });
      router.push({ query: { news: id } });
      isLoading.value = isFetching;
      store.commit("OPEN_NEWS_SIDE_BAR");
    }

    function setRootParent(id) {
      useFetch(`https://admin.dss-sport.ru/wp-json/wp/v2/pages/${id}?_embed`, {
        afterFetch(ctx) {
          let raw = JSON.parse(ctx.data);
          if (raw.root_parent === 2) {
            pageDataType.value = "sport";
          } else if (raw.root_parent === 299) {
            pageDataType.value = "vacation";
          } else if (raw.root_parent === 335) {
            pageDataType.value = "management";
          }
        },
      });
    }

    function setPage(id) {
      if (pageDataType.value === "") {
        switch (id) {
          case 2:
            router.push({
              name: "Category",
              params: { slug: "sport" },
            });
            break;
          case 299:
            router.push({
              name: "Category",
              params: { slug: "vacation" },
            });
            break;
          case 335:
            router.push({
              name: "Category",
              params: { slug: "management" },
            });
            break;
        }
      } else if (props.item.id === 2) {
        router.push({
          name: "Reception",
          params: { type: pageDataType.value },
        });
      } else {
        router.push({
          name: "Page",
          params: { type: pageDataType.value, slug: id },
        });
      }
    }

    onMounted(() => {
      if (props.item?.subtype === "page") setRootParent(props.item.id);
    });

    return {
      setNews,
      setPage,
      pageData,
      pageDataType,
      setRootParent,
    };
  },
};
</script>
<template>
  <div class="search__item">
    <template v-if="item?.subtype === 'post'">
      <div class="search__item__body" @click="setNews(item.id)">
        <a class="search__item__top">
          <h4>{{ position + 1 }}. {{ item.title }}</h4>
        </a>
        <div class="search__item__bottom">Новость</div>
      </div>
    </template>
    <template v-else-if="item?.subtype === 'page'">
      <div class="search__item__body" @click="setPage(item.id)">
        <a class="search__item__top">
          <h4>{{ position + 1 }}. {{ item.title }}</h4>
        </a>
        <div class="search__item__bottom">Страница</div>
      </div>
    </template>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.search__item
  position: relative
  margin-bottom: $default-padding*2
  &__body
    display: flex
    flex-direction: column
    cursor: pointer
    gap: $default-padding
    & h4
      color: $primary-color
      font-size: $base-font-size*1.25
      font-weight: 500
  &__bottom
    font-style: italic
</style>
